.completeprofile{
    color: #41436A;
    font-weight: bold;
    margin-left: 50px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.form-control{
    color: #41436A;
}

.profileform-left{
    background-color: white;
    border-radius: 10px;
    height: auto;
    color: #41436A;
    width:575px;
    padding: 20px;
    margin-left: 50px;
    position: absolute;
}

.profileform-right{
    background-color: white;
    border-radius: 10px;
    height: auto;
    color: #41436A;
    width:575px;
    margin-left: 700px;
    padding: 20px;
}

.profileform-middle {
    margin-top: 50px;
}

.profileform-parent{
    margin-top: 120px;
}

.profileform-sibiling{
    margin-top: 50px;
}

.profileform-images{
    margin-top: 150px;
    background-color: white;
    border-radius: 10px;
    color: #41436A;
    width:1220px;
    margin-left: 50px;
    padding: 20px;
}

.profileform-btn{
    position: absolute;
    margin-top: 50px;
}

.profileform-horoscope{
    background-color: white;
    border-radius: 10px;
    height: auto;
    color: #41436A;
    width:1220px;
    margin-left: 50px;
    padding: 20px;
    margin-top: 50px;
}

.profileform h5, .profileform-images h5{
    color: #74769b;
    font-weight: bold;
    padding-top: 20px;
    padding-left: 30px;
}

.profileform-horoscope h5{
    color: #74769b;
    font-weight: bold;
    padding-top: 20px;
    padding-left: 30px;
}

.image-left{
    float: left;
    margin-left: 30px;
}

.image-right{
    float:right;
    margin-right: 100px;
}

.personaldet{
    color: #74769b;
    font-weight: bold;
    padding-top: 20px;
    padding-left: 30px;
}

.profileform .form-label, .profileform-images .form-label{
    margin-left: 30px;
    margin-top: 20px;
    color: #41436A;
}

.profileform .form-control{
    margin-left: 30px;
}

.profileform .mb-3 input, .profileform .mb-3 textarea{
    width: 480px;
    color: #41436A;
}

.continuebtn1{
    background-color: #41436A;
    color: white;
    border: none;
    width: 150px;
    height: 40px;
    font-size: 16px;
    border-radius: 10px;
    margin-left: 200px;
    margin-top: 35px;
    margin-bottom: 20px;
}

input[type='date']{
    color: #41436A;
}

.vl3{
    height: 600px;
    border-left: 1px solid #c8c9d4;
    margin-top: 70px;
    margin-left: 50px;
}

.continuebtn2{
    background-color: #41436A;
    color: white;
    border: none;
    width: 150px;
    height: 40px;
    font-size: 16px;
    border-radius: 10px;
    float: right;
    margin-left: 920px;
}

.continuebtn3{
    background-color: #41436A;
    color: white;
    border: none;
    width: 150px;
    height: 40px;
    font-size: 16px;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 30px;
    float: right;
    margin-right: 30px;
}

.resetbtn{
    background-color: #41436A;
    color: white;
    border: none;
    width: 150px;
    height: 40px;
    font-size: 16px;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 30px;
    float: left;
    margin-left: 50px;
}


.backbtn1{
    background-color: #41436A;
    color: white;
    border: none;
    width: 150px;
    height: 40px;
    font-size: 16px;
    border-radius: 10px;
    float:left;
    margin-left: 50px;
}

#det {
    margin-top: 30px;
    height: 150px;
}

.myprofile{
    width:1000px;
    border-radius: 20px;
    background-color: white;
    margin: 20px;
    height: auto;
    padding: 20px;
    margin-left: 150px;
    margin-bottom: 30px;
}

.myprofiletitle{
    color: #41436A;
    font-weight: bold;
    margin-left: 150px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.editprofile {
    text-decoration: none;
    color: #41436A;
    font-weight: bold;
    float: right;
    margin-right: 20px;
}

.myprofilevalues{
    color: #41436A;
    font-weight: bold;
    
}

.donebtn{
    background-color: #41436A;
    color: white;
    border: none;
    width: 150px;
    height: 40px;
    font-size: 16px;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.profile-control{
    margin-left: 30px;
    color: #41436A;
    margin-top: 20px;
}

.profile-value{
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 10px;
    margin-left: 50px;
    width: 300px;
    text-align: center;
    color: #41436A;
    border-bottom:1px solid #41436A;
    margin-bottom: 10px;
    margin-top: 10px;
}

#profile-value{
    margin-left: 50px;
    width: 800px;
    margin-top: 30px;
    border:1px solid #41436A;
    margin-bottom: 20px;
}

#parent, #parent-det{
    margin-bottom:4px;
    margin-left: -20px;
}

.horoscopetemplate1{
    margin-left: 30px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.horoscopetemplate2{
    margin-left: 680px;
    position: absolute;
    margin-top: -527px;
}

.row1, .row2{
    border: 1px solid #41436A;
    width: 480px;
    height: 160px;
    border-bottom: none;
    display: block;
}

.row3{
    border: 1px solid #41436A;
    width: 480px;
    height: 160px;
}

.square1{
    border-right: 1px solid #41436A;
    width: 160px;
    height: 160px;
}

.square2{
    border-right: 1px solid #41436A;
    width: 160px;
    height: 160px;
    margin-top: -160px;
    margin-left: 160px;
}

.square3{
    border: none;
    width: 160px;
    height: 160px;
    margin-top: -160px;
    margin-left: 320px;
}

.cross1{
    border-top: 1px solid #41436A;
    width: 225px;
    transform: rotate(45deg);
    margin-top: -81px;
    margin-left: -33px;
}

.cross2{
    border-top: 1px solid #41436A;
    width: 225px;
    transform: rotate(-45deg);
    margin-left: 286px;
}

.cross3{
    border-top: 1px solid #41436A;
    width: 225px;
    transform: rotate(-45deg);
    margin-top: -81px;
    margin-left: -33px;
}

.cross4{
    border-top: 1px solid #41436A;
    width: 225px;
    transform: rotate(45deg);
    margin-left: 287px;
}

.L1{
    margin-top: 20px;
    width: 60px;
    margin-left: 80px;
    height: 50px;
    text-align: center;
}

.L2{
    margin-top: 20px;
    width: 60px;
    margin-left: 20px;
    height: 50px;
    text-align: center;
}

.L3{
    margin-top: 40px;
    width: 100px;
    margin-left: 30px;
    height: 70px;
    text-align: center;
}

.L4{
    margin-top: 20px;
    width: 60px;
    margin-left: 20px;
    height: 50px;
    text-align: center;
}

.L5{
    margin-top: 20px;
    width: 60px;
    margin-left: 80px;
    height: 50px;
    text-align: center;
}

.save{
    border-radius: 20px;
    margin-top: -70px;
    margin-left: 1060px;
    background-color: #41436A;
    border: none;
    width: 100px;
    height: 40px;
    font-size: 16px;
    color: white;
    font-weight: bold;
    position: absolute;
}

.sharebtn{
    background-color: inherit;
    color: #41436A;
    border: 2px solid #41436A;
    width: 200px;
    height: 40px;
    font-size: 16px;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.printbtn{
    background-color: inherit;
    color: #41436A;
    border: 2px solid #41436A;
    width: 150px;
    height: 40px;
    font-size: 16px;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.requestbtn{
    background-color: #41436A;
    color: white;
    border: none;
    width: 150px;
    height: 40px;
    font-size: 16px;
    border-radius: 50px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.upload{
    border-radius: 20px;
    background-color: #41436A;
    border: none;
    width: 100px;
    height: 40px;
    font-size: 16px;
    color: white;
    font-weight: bold;
    float:right;
    margin-top: -40px;
    margin-right: -700px;
}

.horoscopetemplate3{
    margin-left: 30px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.horoscopetemplate4{
    margin-left: 570px;
    position: absolute;
    margin-top: -410px;
}

.horoscopetemplatepp{
    margin-left: 480px;
    position: absolute;
    margin-top: -410px;
}

.rowdet1, .rowdet2{
    border: 1px solid #41436A;
    width: 360px;
    height: 120px;
    border-bottom: none;
    display: block;
}

.squaredet1{
    border-right: 1px solid #41436A;
    width: 120px;
    height: 120px;
}

.squaredet2{
    border-right: 1px solid #41436A;
    width: 120px;
    height: 120px;
    margin-top: -120px;
    margin-left: 120px;
}

.squaredet3{
    border: none;
    width: 120px;
    height: 120px;
    margin-top: -120px;
    margin-left: 240px;
}

.rowdet3{
    border: 1px solid #41436A;
    width: 360px;
    height: 120px;
}

.value1{
    margin-top: 20px;
    width: 40px;
    margin-left: 60px;
    height: 30px;
    text-align: center;
}

.value2{
    margin-top: 20px;
    width: 40px;
    margin-left: 10px;
    height: 30px;
    text-align: center;
}

.value3{
    margin-top: 30px;
    width: 70px;
    margin-left: 25px;
    height: 60px;
    text-align: center;
}

.value4{
    margin-top: 20px;
    width: 40px;
    margin-left: 10px;
    height: 30px;
    text-align: center;
}

.value5{
    margin-top: 20px;
    width: 40px;
    margin-left: 60px;
    height: 30px;
    text-align: center;
}

.horoscopetemplate3 input, .horoscopetemplate4 input, .horoscopetemplatepp input{
    border: none;
    color: #41436A;
}

.horoscopetemplate1 input, .horoscopetemplate2 input{
    color: #41436A;
    border: 1px solid #41436A;
    border-radius: 5px;
}

.cross5{
    border-top: 1px solid #41436A;
    width: 167px;
    transform: rotate(45deg);
    margin-top: -61px;
    margin-left: -24px;
    position: relative;
}

.cross6{
    border-top: 1px solid #41436A;
    width: 167px;
    transform: rotate(-45deg);
    margin-left: 215px;
    position: relative;
}

.cross7{
    border-top: 1px solid #41436A;
    width: 167px;
    transform: rotate(-45deg);
    margin-top: -61px;
    margin-left: -24px;
    position: relative;
}

.cross8{
    border-top: 1px solid #41436A;
    width: 167px;
    transform: rotate(45deg);
    margin-left: 216px;
    position: relative;
}

.custom-carousel{
    width:50%;
}
.custom-carousel .slider{
    background: none;
}

.custom-carousel .carousel .control-next.control-arrow {
    right: 40px;

}

.custom-carousel .carousel .control-prev.control-arrow {
    left: 40px;
}

.custom-carousel .carousel .carousel-status {
    color:#41436A;
    text-shadow:none;
}

.custom-carousel .carousel.carousel-slider button:hover{
    background: none;
}

.vlcp{
    height: 580px;
    border-left: 1px solid #c8c9d4;
    margin-top: 100px;
}

.vlcpcustomer{
    height: 580px;
    border-left: 1px solid #c8c9d4;
    margin-top: 20px;
}

.aboutMe{
    color:#41436A;
}

.createPost input{
    border-radius: 30px;
    text-align: center;
    color:#41436A;
    width: 250px;
    margin-left: 50px;
}

.createPost label{
    margin-bottom: 18px;
}

.searchDiv{
    width:1280px;
    border-radius: 20px;
    background-color: white;
    margin: 20px;
    height: auto;
    padding: 20px;
    margin-left: 10px;
    margin-bottom: 30px;
    margin-top: 30px;
    margin-right: 10px;
}

#exampleFormControlSelect1{
    width:480px;
}

.searchDiv .block{
    width:300px;
    border-radius: 20px;
    background-color: white;
    margin: 20px;
    height: auto;
    margin-left: 50px;
    margin-right: 50px;
    box-shadow: 2px 5px 5px 5px #dddcdc;
}

.searchDiv .filter{
    width:1200px;
    border-radius: 20px;
    background-color: white;
    margin: 20px;
    height: 200px;
    margin-bottom: 50px;
    box-shadow: 2px 5px 5px 5px #dddcdc;
}

.filterNav {
    float: left;
    overflow: hidden;
}
  
.filterNav .filterNavbtn {
    font-size: 16px;
    border: none;
    outline: none;
    color: #41436A;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
    font-weight: bold;
    margin-top: 15px;
}

.filterNavbtn:hover {
    border-color: #F54768;
    color: #F54768;
}

#disable{
    color: #41436A;
}

.form-filter{
    padding: 8px 10px;
    display: inline-block;
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.select{
    padding: 8px 10px;
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    max-height: 200px;
}

.autocomplete-list {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    z-index: 1000;
    width: 200px;
    background-color: #fff;
    border: 1px solid #ddd;
    max-height: 200px;
    overflow-y: auto;
    margin-left: 80px;
}
  
.autocomplete-list li {
    padding: 8px;
    cursor: pointer;
}
  
.autocomplete-list li:hover,
.autocomplete-active {
    background-color: #f1f1f1;
}

#autocomplete-container {
    display: inline-block;
}

.verticalContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.viewbtn{
    text-decoration: none;
}

.horoscopediv{
    margin-left:10px; 
    box-shadow: 2px 5px 5px 5px #dddcdc;
    border-radius:20px;
}

.mobileHoroscope, .mobileHoroscopeEdit, .mobile-carousel{
    display: none;
}

#mobile-dropdown{
    display:none;
}

.select-body{
    width: 480px;
    height: 40px;
    display: flex;
    flex-direction: column;
}

.select-body select{
    width: 100%;
    height: 100%;
    padding: 0 30px 0 10px;
    appearance: none;
    color: #41436A;
}

.mobileview{
    display:none;
}

@media only screen and (max-width: 493px) {
    .profileform-left {
        transform: translateX(-9%);
        max-width: 380px;
        font-size: smaller;
        width: 100%;
        position: relative;
    }

    .profileform-right {
        transform: translateX(-250%);
        left: -166%;
        max-width: 380px;
        font-size: smaller;
        width: 100%;
        position: relative;
        transform: translateY(112%);
    }

    .profileform-middle{
        transform: translateY(130%);
    }

    .profileform-parent{
        transform: translateY(138%);
    }

    .profileform-sibiling{
        transform: translateY(665%);
    }

    .profileform-sibiling .profileform-right{
        transform: translateY(110%);
    }

    .profileform-middle .profileform-right{
        transform: translateY(116%);
    }

    .profileform-parent .profileform-right{
        transform: translateY(151%);
    }

    .profileform-parent .profileform-left{
        margin-top: 330px;
    }

    .profileform .mb-3 input,
    .profileform .mb-3 textarea,
    .profileform .mb-3 select{
        width: 100%;
        max-width: 300px;
    }

    .horoscopetemplate1, .horoscopetemplate2{
        display: none;
    }

    .footer{
        display: none;
    }

    .backbtn1, .continuebtn2{
        display: block;
    }

    .backbtn1{
        transform: translateY(5800%);
        max-width:100px;
        position: relative;
        left: -10px;
    }

    .continuebtn2{
        transform: translateY(5700%);
        max-width:100px;
        position: relative;
        left:-63%;
    }

    .profileform-images{
        transform: translateY(525%);
        position: relative;
        left: -32px;
        max-width: 380px;
        height: 100%;
        max-height: 500px;
    }

    .imageAdd{
        position: relative;
        left: 0; 
        font-size: 14px;    
    }

    .profileform-images .mb-3 input{
        width: 100%;
        max-width: 230px;
        margin-top: 20px;
        font-size: 14px;
        margin-left: -10px;
    }

    .spanProfile{
        transform: translateY(-150%);
        position: relative;
        left:-240px;
    }

    .upload{
        transform: translateX(-800%);
        left:-20%;
        max-width: 75px;
        font-size: 14px;
    }

    .profileform-horoscope{
        display: block;
        position: relative;
        left: -32px;
        max-width: 380px;
    }

    .mobileHoroscope{
        display: block;
        background-color: white;
        width: 380px;
        border-radius: 10px;
        margin-left:-20px;
        height: 800px;
    }

    .mobileHoroscopeEdit{
        display: block;
        background-color: white;
        width: 380px;
        border-radius: 10px;
        margin-left:-20px;
        height: 800px;
    }

    .horoscopeView{
        background-color: white;
        width: 380px;
        border-radius: 10px;
        margin-left:-20px;
        height: 700px;
    }

    .horoscopetemplate3{
        left: -20px;
        position: relative;
    }

    .horoscopetemplate4{
        left: -560px;
        position: relative;
        transform: translateY(115%);
    }

    .spanHoroscope{
        transform: translateY(-10%);
        position: relative;
        left:0;
    }

    .save{
        left:-210%;
    }

    #editimage{
        transform: translateY(590%);
    }

    .spanEditHoroscope{
        transform: translateY(-150%);
        position: relative;
        left:-110%;
    }

    #editHoroscope{
        transform: translateY(245%);
    }

    #completeHoroscope{
        transform: translateY(220%);
    }

    #editimage{
        transform: translateY(610%);
    }

    .myprofiletitle{
        margin-left:-100px;
    }

    .myprofile{
        transform: translateX(-35%);
        max-width: 380px;
        font-size: smaller;
        width: 100%;
        position: relative;
    }

    .profile-value{
        max-width: 150px;
        text-align: left;
        left:-30%;
        transform: translateX(-35%);
    }

    .profile-control{
        left:-10%;
        transform: translateX(-15%);
    }

    .resetbtn, .continuebtn3{
        transform: translateY(5700%);
        max-width:100px;
        position: relative;
        left:-10px;
    }

    .myprofile textarea{
        max-width:250px;
    }

    #parentdet{
        margin-top: 40px;
    }

    #parent-det{
        margin-top: 50px;
        text-align: center;
    }

    .parent{
        margin-top: 25px;
        margin-left: 15px;
        width: 70px;
        text-align: center;
    }

    .groom{
        max-width:250px;
    }

    .mobile-carousel{
        display:block;
    }

    .web-carousel{
        display: none;
    }

    .mobile-carousel .custom-carousel{
        width:100%;
        max-width:500px;
        margin-left: -10px;

    }

    .custom-carousel .slider{
        background: none;
    }
    
    .custom-carousel .carousel .control-next.control-arrow {
        right: 40px;
    }
    
    .custom-carousel .carousel .control-prev.control-arrow {
        left: 40px;
    }
    
    .custom-carousel .carousel .carousel-status {
        color:#41436A;
        text-shadow:none;
    }
    
    .custom-carousel .carousel.carousel-slider button:hover{
        background: none;
    }

    .donebtn{
        transform: translateY(350%);
    }

    .option-resize {
        font-size: 10px; 
    }

    #exampleFormControlSelect1 option{
        width: 100%;
        max-width: 200px;
    }

    .myprofiletitle{
        font-size:18px;
    }

    #createPost, #viewpost, #editpost{
        left: -50px;
        transform: translateX(-90%);
    }

    #createpostinput, #viewpostinput, #editpostinput{
        left: -30px;
        transform: translateX(-30%);
    }

    #createpostinput input, #viewpostinput input, #editpostinput input{
        max-width: 130px;
        margin-top: 7px;
    }

    .vlcp{
        transform: translateY(-10%);
    }

    #horoscopecreatepost{
        width: 380px;
        transform: translateX(-15%);
        height:950px;
    }

    #horoscopecreatepost .horoscopetemplate3{
        transform: translateX(-6%);
    }

    #horoscopecreatepost .horoscopetemplatepp{
        transform: translateX(-130%);
        margin-top: 0;
    }

    .donebtn{
        transform: translateY(-20%);
    }

    #btn1, #btn2{
        width:120px;
    }

    .searchDiv{
        max-width: 390px;
    }

    .searchDiv .filter{
        max-width: 370px;
        margin-left:-10px;
        height: 270px;
    }

    #filterbtn1{
        transform: translateX(-20%);
        margin-top:-5px;
    }

    #filterbtn2{
        transform: translateX(-55%);
        margin-top:-42px;
        width:300px;
    }

    #filterbtn3{
        transform: translateX(-80%);
        margin-top:-5px;
        width:300px;
    }

    #autocomplete-container{
        transform: translateX(30%);
        margin-top:-30px;
    }

    .filterNav input[type="text"]{
        max-width:100px;
        height: 30px;
    }

    #autocomplete-container input{
        width:200px;
    }

    .mobileview{
        display:block;
    }

    .webview{
        display:none;
    }

    .verticalContainer{
        display: flex;
        flex-direction: column;
        transform: translateX(-20%);
    }

    .filterNav input[type='number']{
        max-width:50px;
        height: 30px;
    }

    #castebtn{
        transform: translateX(-40%);
    }

    #religionbtn{
        transform: translateX(-37%);
        margin-top: 5px;
    }

    #viewbtnmobile{
        margin-left: -10%;
    }

    #viewpostmobile h6{
        text-align: center;
    }

    #customerviewpost{
        left: 10px;
        transform: translateX(5%);
        width: 500px;
    }

    #customerviewpost label{
        display:flex;
        flex-direction: column;
    }

    #vlcpmobile{
        display: none;
    }

    #requestmobile{
        transform: translateX(-900%);
        top:88%;
        position: relative;
    }

    #requestmobile .aboutMe{
        width: 200px;
        margin-left:-25px;
    }
    
    #horoscopeviewpost{
        width: 380px;
        transform: translateX(-15%);
        height:950px;
        margin-top: 125%;
    }

    #horoscopeviewpost .horoscopetemplate3{
        transform: translateX(-6%);
    }

    #horoscopeviewpost .horoscopetemplatepp{
        transform: translateX(-130%);
        margin-top: 0;
    }

    #mobileprint{
        margin-top: 130%;
        transform: translateX(200%);
    }

    .autocomplete-list{
        transform: translateY(-100%);
    }

    #horoscopeprintpost{
        width: 380px;
        transform: translateX(-15%);
        height:950px;
    }

    #horoscopeprintpost .horoscopetemplate3{
        transform: translateX(-6%);
    }

    #horoscopeprintpost .horoscopetemplatepp{
        transform: translateX(-130%);
        margin-top: 0;
    }

    .printbtn{
        width:120px;
    }

    .viewbtn{
        left:-18%;
        position: relative;
    }

}
