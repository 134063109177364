body{
    background-color: #F4F4F4;
}

.dashboardTop{
    background-color: #ffffff;
}

.dashboardTop {
    float: left;
    overflow: hidden;
    margin-left: 30px;
    border-radius: 10px;
    margin-right: 20px;
    width: 275px;
    height: 100px;
}
  
.dashboardTop .dashboardTopBlock {
    border: none;
    outline: none;
    color: #41436A;
    padding-left: 16px;
    padding-bottom: 10px;
    background-color: inherit;
    font-family: inherit;
    font-weight: bold;
    margin-top: 15px;
}

.dashboardTopBlock img{
    width: 75px;
    margin-right: 20px;
}

.dashboardTopBlock .name{
    font-size: 18px;
    position: absolute;
    margin-top: 10px;
    margin-bottom: 50px;
}

.dashboardTopBlock .value{
    margin-top: 35px;
    position: absolute;
    margin-left: 15px;
    font-size: 16px;
}

.fa-star {
    font-size: 60px;
    color: rgb(241, 238, 4);
    margin-top: 5px;
    margin-right: 20px;
}

.dashboardSubbtn {
    float: left;
    overflow: hidden;
}

.dashboardSub{
    margin-left: 30px;
    margin-top: 30px;
}

.dashboardSubbtn .subbtn {
    border: 1px solid #ffffff;
    color: #ffffff;
    text-align: center;
    font-family: inherit;
    font-weight: bold;
    margin-top: 15px;
    background-color: #41436A;
    padding: 10px;
    width: 250px;
}

.dashboardSubbtn #btn1{
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.dashboardSubbtn #btn5{
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.earnings{
    background-color: #ffffff;
    width: 1250px;
    border-radius: 15px;
    height: auto;
    margin-left: 30px;
    color: #41436A;
    box-shadow: 0 4px 8px 0 rgba(66, 68, 108, 0.2), 0 6px 20px 0 rgba(66, 68, 108, 0.19);
}

.earnings h5, .report h5{
    padding:30px;
    color: #41436A;
    font-weight: bold;
}

.earnings table{
    margin-left: 30px;
}

.report{
    background-color: #ffffff;
    width: 1250px;
    border-radius: 15px;
    height: auto;
    margin-left: 30px;
    color: rgb(66, 68, 108);
    box-shadow: 0 4px 8px 0 rgba(66, 68, 108, 0.2), 0 6px 20px 0 rgba(66, 68, 108, 0.19);
}

.report ul{
    list-style: none;
    padding: 0;
    margin-top: 75px;
}

.report li {
    display: flex;
    align-items: center;
    margin-bottom: 10px; 
}

.report i {
    margin-right: 20px; 
}

#promo1{
    color: #fa2b5f;
    font-size: 32px;
}

#promo2{
    color: #00ff73;
    font-size: 32px;
}

#promo3{
    color: #00ffff;
    font-size: 32px;
}

#promo4{
    color: #2e355c;
    font-size: 32px;
}

#promo5{
    color: #f8f678;
    font-size: 32px;
}

.manageSub{
    margin-left: 50px;
    float: left;
    overflow: hidden;
}

.manageSubbtn {
    float: left;
    overflow: hidden;
}

.manageSubbtn .subbtn {
    text-align: center;
    font-family: inherit;
    font-weight: bold;
    margin-top: 15px;
    padding: 6px;
    width: 200px;
    border:none;
}

#active{
    background-color: inherit;
    color: #41436A;
    border:2px solid #41436A;
    height: 40px;
}

#deactive{
    background-color: #41436A;
    color: #ffffff;
    border-top:2px solid #41436A;
    border-bottom:2px solid #41436A;
    border-right:2px solid #41436A;
}

.customerProfile{
    border:1px solid #b6b6bd;
    margin:0 50px 50px 50px;
    border-radius: 10px;
    background-color: white;
}

.customerProfile h6{
    padding-top:20px;
    padding-left: 20px;
    color: #41436A;
    font-weight: bold;
}

.accountStatus{
    margin-left: 25px;
    color: #41436A;
    font-weight: bold;
}

#empty{
    background-color: #41436A;
    color: #41436A;
    border-top:2px solid #41436A;
    border-bottom:2px solid #41436A;
    border-left:2px solid #41436A;
    height:40px;
}

.profileView{
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(66, 68, 108, 0.2), 0 6px 20px 0 rgba(66, 68, 108, 0.19);
    margin-top: 30px;
    padding: 30px;
    color: #41436A;
    margin-right:200px;
    margin-left: 200px;
    margin-bottom: 50px;
}

.profileView h5{
    color: #41436A;
    font-weight: bold;
}

.profileImg{
    width: 150px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.labels {
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 25px;
}

.labels .label2{
    text-align: center;
    margin-left: 260px;
}

.subscribe{
    margin-left: 100px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.subscribe h6{
    margin-bottom: 20px;
}

.hrclass{
    margin-left: 50px;
    margin-bottom: 30px;
    margin-right: 50px;
}

#subcribe{
    margin-left: 50px;
}

.cancelProfile{
    border-radius: 10px;
    background-color: #41436A;
    color: white;
    border: none;
    width: 150px;
    font-size: 18px;
    padding: 6px;
    margin-top: 20px;
}

#back{
    float: left;
    margin-left: 50px;
}

#cancelProfile{
    float: right;
    margin-right: 50px;
}

.deactivebtn{
    margin-top: 20px;
    border-radius: 20px;
    border: 2px solid #41436A;
    color: #41436A;
    background-color: inherit;
}

.reason{
    margin-top: 30px;
    margin-left: 50px;
    margin-bottom: 30px;
}

.reason input{
    width:750px;
    background-color: #f7f7fd;
    height: 50px;
    border-radius: 10px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
  
input:checked + .slider {
    background-color: #2196F3;
}
  
input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}
  
input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}
  
  
.slider.round {
    border-radius: 34px;
}
  
.slider.round:before {
    border-radius: 50%;
}

#switch1{
    float: left;
    margin-left: 50px;
    margin-top: 20px;
}

#switch2{
    margin-left: 500px;
    margin-top: 20px;
}

.slider .activateName, .slider .deactivateName{
    margin:100px;
    font-size: 18px;
}

.completeprofile{
    color: #41436A;
    font-weight: bold;
    margin-left: 50px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.form-control{
    color: #41436A;
}

.profileform-left{
    background-color: white;
    border-radius: 10px;
    height: auto;
    color: #41436A;
    width:575px;
    padding: 20px;
    margin-left: 50px;
    position: absolute;
}

.profileform-right{
    background-color: white;
    border-radius: 10px;
    height: auto;
    color: #41436A;
    width:575px;
    margin-left: 700px;
    padding: 20px;
}

.profileform-middle {
    margin-top: 50px;
}

.profileform-parent{
    margin-top: 120px;
}

.profileform-sibiling{
    margin-top: 50px;
}

.profileform-images{
    margin-top: 150px;
    background-color: white;
    border-radius: 10px;
    height: auto;
    color: #41436A;
    width:1220px;
    margin-left: 50px;
    padding: 20px;
}

.profileform-btn{
    position: absolute;
    margin-top: 50px;
}

.profileform-horoscope{
    background-color: white;
    border-radius: 10px;
    height: auto;
    color: #41436A;
    width:1220px;
    margin-left: 50px;
    padding: 20px;
    margin-top: 50px;
}

.profileform h5, .profileform-images h5{
    color: #74769b;
    font-weight: bold;
    padding-top: 20px;
    padding-left: 30px;
}

.profileform-horoscope h5{
    color: #74769b;
    font-weight: bold;
    padding-top: 20px;
    padding-left: 30px;
}

.image-left{
    float: left;
    margin-left: 30px;
}

.image-right{
    float:right;
    margin-right: 100px;
}

.personaldet{
    color: #74769b;
    font-weight: bold;
    padding-top: 20px;
    padding-left: 30px;
}

.profileform .form-label, .profileform-images .form-label{
    margin-left: 30px;
    margin-top: 20px;
    color: #41436A;
}

.profileform .form-control{
    margin-left: 30px;
}

.profileform .mb-3 input, .profileform .mb-3 textarea{
    width: 480px;
    color: #41436A;
}

.continuebtn1{
    background-color: #41436A;
    color: white;
    border: none;
    width: 150px;
    height: 40px;
    font-size: 16px;
    border-radius: 10px;
    margin-left: 200px;
    margin-top: 35px;
    margin-bottom: 20px;
}

input[type='date']{
    color: #41436A;
}

.vl3{
    height: 600px;
    border-left: 1px solid #c8c9d4;
    margin-top: 70px;
    margin-left: 50px;
}

.continuebtn2{
    background-color: #41436A;
    color: white;
    border: none;
    width: 150px;
    height: 40px;
    font-size: 16px;
    border-radius: 10px;
    float: right;
    margin-left: 920px;
}

.continuebtn3{
    background-color: #41436A;
    color: white;
    border: none;
    width: 150px;
    height: 40px;
    font-size: 16px;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 30px;
    float: right;
    margin-right: 30px;
}

.resetbtn{
    background-color: #41436A;
    color: white;
    border: none;
    width: 150px;
    height: 40px;
    font-size: 16px;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 30px;
    float: left;
    margin-left: 50px;
}


.backbtn1{
    background-color: #41436A;
    color: white;
    border: none;
    width: 150px;
    height: 40px;
    font-size: 16px;
    border-radius: 10px;
    float:left;
    margin-left: 50px;
}

#det {
    margin-top: 30px;
    height: 150px;
}

.myprofile{
    width:1000px;
    border-radius: 20px;
    background-color: white;
    margin: 20px;
    height: auto;
    padding: 20px;
    margin-left: 150px;
    margin-bottom: 30px;
}

.myprofiletitle{
    color: #41436A;
    font-weight: bold;
    margin-left: 150px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.editprofile {
    text-decoration: none;
    color: #41436A;
    font-weight: bold;
    float: right;
    margin-right: 20px;
}

.myprofilevalues{
    color: #41436A;
    font-weight: bold;
    
}

.donebtn{
    background-color: #41436A;
    color: white;
    border: none;
    width: 150px;
    height: 40px;
    font-size: 16px;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.profile-control{
    margin-left: 30px;
    color: #41436A;
    margin-top: 20px;
}

.profile-value{
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 10px;
    margin-left: 50px;
    width: 300px;
    text-align: center;
    color: #41436A;
    border-bottom:1px solid #41436A;
    margin-bottom: 10px;
    margin-top: 10px;
}

#profile-value{
    margin-left: 50px;
    width: 800px;
    margin-top: 30px;
    border:1px solid #41436A;
    margin-bottom: 20px;
}

#parent{
    margin-bottom:4px;
    margin-left: -20px;
}

.horoscopetemplate1{
    margin-left: 30px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.horoscopetemplate2{
    margin-left: 680px;
    position: absolute;
    margin-top: -527px;
}

.row1, .row2{
    border: 1px solid #41436A;
    width: 480px;
    height: 160px;
    border-bottom: none;
    display: block;
}

.row3{
    border: 1px solid #41436A;
    width: 480px;
    height: 160px;
}

.square1{
    border-right: 1px solid #41436A;
    width: 160px;
    height: 160px;
}

.square2{
    border-right: 1px solid #41436A;
    width: 160px;
    height: 160px;
    margin-top: -160px;
    margin-left: 160px;
}

.square3{
    border: none;
    width: 160px;
    height: 160px;
    margin-top: -160px;
    margin-left: 320px;
}

.cross1{
    border-top: 1px solid #41436A;
    width: 225px;
    transform: rotate(45deg);
    margin-top: -81px;
    margin-left: -33px;
}

.cross2{
    border-top: 1px solid #41436A;
    width: 225px;
    transform: rotate(-45deg);
    margin-left: 286px;
}

.cross3{
    border-top: 1px solid #41436A;
    width: 225px;
    transform: rotate(-45deg);
    margin-top: -81px;
    margin-left: -33px;
}

.cross4{
    border-top: 1px solid #41436A;
    width: 225px;
    transform: rotate(45deg);
    margin-left: 287px;
}

.L1{
    margin-top: 20px;
    width: 60px;
    margin-left: 80px;
    height: 50px;
    text-align: center;
}

.L2{
    margin-top: 20px;
    width: 60px;
    margin-left: 20px;
    height: 50px;
    text-align: center;
}

.L3{
    margin-top: 40px;
    width: 100px;
    margin-left: 30px;
    height: 70px;
    text-align: center;
}

.L4{
    margin-top: 20px;
    width: 60px;
    margin-left: 20px;
    height: 50px;
    text-align: center;
}

.L5{
    margin-top: 20px;
    width: 60px;
    margin-left: 80px;
    height: 50px;
    text-align: center;
}

.save{
    border-radius: 20px;
    margin-top: -70px;
    margin-left: 1060px;
    background-color: #41436A;
    border: none;
    width: 100px;
    height: 40px;
    font-size: 16px;
    color: white;
    font-weight: bold;
    position: absolute;
}

.upload{
    border-radius: 20px;
    background-color: #41436A;
    border: none;
    width: 100px;
    height: 40px;
    font-size: 16px;
    color: white;
    font-weight: bold;
    float:right;
    margin-top: -40px;
    margin-right: -700px;
}

.horoscopetemplate3{
    margin-left: 30px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.horoscopetemplate4{
    margin-left: 570px;
    position: absolute;
    margin-top: -410px;
}

.rowdet1, .rowdet2{
    border: 1px solid #41436A;
    width: 360px;
    height: 120px;
    border-bottom: none;
    display: block;
}

.squaredet1{
    border-right: 1px solid #41436A;
    width: 120px;
    height: 120px;
}

.squaredet2{
    border-right: 1px solid #41436A;
    width: 120px;
    height: 120px;
    margin-top: -120px;
    margin-left: 120px;
}

.squaredet3{
    border: none;
    width: 120px;
    height: 120px;
    margin-top: -120px;
    margin-left: 240px;
}

.rowdet3{
    border: 1px solid #41436A;
    width: 360px;
    height: 120px;
}

.value1{
    margin-top: 20px;
    width: 40px;
    margin-left: 60px;
    height: 30px;
    text-align: center;
}

.value2{
    margin-top: 20px;
    width: 40px;
    margin-left: 10px;
    height: 30px;
    text-align: center;
}

.value3{
    margin-top: 30px;
    width: 70px;
    margin-left: 25px;
    height: 60px;
    text-align: center;
}

.value4{
    margin-top: 20px;
    width: 40px;
    margin-left: 10px;
    height: 30px;
    text-align: center;
}

.value5{
    margin-top: 20px;
    width: 40px;
    margin-left: 60px;
    height: 30px;
    text-align: center;
}

.horoscopetemplate3 input, .horoscopetemplate4 input{
    border: none;
    color: #41436A;
}

.horoscopetemplate1 input, .horoscopetemplate2 input{
    color: #41436A;
    border: 1px solid #41436A;
    border-radius: 5px;
}

.cross5{
    border-top: 1px solid #41436A;
    width: 167px;
    transform: rotate(45deg);
    margin-top: -61px;
    margin-left: -24px;
    position: relative;
}

.cross6{
    border-top: 1px solid #41436A;
    width: 167px;
    transform: rotate(-45deg);
    margin-left: 215px;
    position: relative;
}

.cross7{
    border-top: 1px solid #41436A;
    width: 167px;
    transform: rotate(-45deg);
    margin-top: -61px;
    margin-left: -24px;
    position: relative;
}

.cross8{
    border-top: 1px solid #41436A;
    width: 167px;
    transform: rotate(45deg);
    margin-left: 216px;
    position: relative;
}

.custom-carousel{
    width:50%;
}
.custom-carousel .slider{
    background: none;
}

.custom-carousel .carousel .control-next.control-arrow {
    right: 40px;

}

.custom-carousel .carousel .control-prev.control-arrow {
    left: 40px;
}

.custom-carousel .carousel .carousel-status {
    color:#41436A;
    text-shadow:none;
}

.custom-carousel .carousel.carousel-slider button:hover{
    background: none;
}