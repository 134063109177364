.navbar img {
    width: 150px;
    margin-left: 30px;
    margin-top: 10px;
}

.nav {
    background-color: white;
    margin: 10px;
    border-radius: 20px;
    height: 85px;
}

.navlink a {
    text-decoration: none;
    color: #41436A;
}

.navlink {
    margin-left: 100px;
    margin-top: 5px;
}

.upgrade {
    border-radius: 50px;
    background-color: #FA0082;
    border: none;
    color: white;
    font-size: 14px;
    margin-left: 75px;
    margin-right: 30px;
    padding: 4px;
    width: 120px;
}

.language,
.help,
.userlogin {
    border: none;
    margin-left: 25px;
    margin-right: 20px;
    color: #41436A;
}

.help a {
    text-decoration: none;
}

.openbtn {
    font-size: 20px;
    cursor: pointer;
    color: #F54768;
    padding: 10px 15px;
    border: none;
    background-color: #ffffff;
    display: none;
}

.openbtn:hover {
    color: #41436A;
}

.fa-user-circle {
    color: #41436A;
    font-size: 24px;
}

.dropbtn {
    color: #41436A;
    padding: 16px;
    font-size: 16px;
    border: none;
    background-color: #ffffff;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #eb2323;
    min-width: 80px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 30px;
    text-align: center;
    margin-top: -10px;
}

.dropdown-content a {
    color: #ffffff;
    padding: 10px 12px;
    text-decoration: none;
    display: block;
    font-size: 14px;
    font-weight: bold;
}

.dropdown-content a:hover {
    background-color: #f85454;
    border-radius: 30px;
    color: black;
    font-weight: bold;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropbtn i {
    padding: 5px;
}

.opt {
    border-color: #41436A;
    background-color: #ffffff;
}

.subnav {
    float: left;
    overflow: hidden;
}

.subnav .subnavbtn {
    font-size: 16px;
    border: none;
    outline: none;
    color: #41436A;
    padding: 10px 16px;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
    font-weight: bold;
    margin-top: 15px;
}

.navbar a:hover,
.subnav:hover .subnavbtn {
    border-color: #F54768;
    color: #F54768;
}

.subnav-content {
    display: none;
    position: absolute;
    left: 340px;
    background-color: #F54768;
    width: auto;
    z-index: 1;
    height: 40px;
    text-align: center;
    border-radius: 10px;
    padding-top: 10px;
    padding-left: 20px;
}

.subnav-content a {
    float: left;
    color: white;
    text-decoration: none;
    font-size: 14px;
    margin-right: 20px;
    font-weight: 300;
}

.subnav-content a:hover {
    color: #41436A;
}

.subnav:hover .subnav-content {
    display: block;
}

.mobile-logout {
    display: none;
}

@media only screen and (max-width: 493px) {
    .navlink .show {
        display: flex;
        flex-direction: column;
        align-items: left;
        background-color: #ffffff;
        position: absolute;
        top: 100px;
        right: 10px;
        border-radius: 10px;
        box-shadow: 0px 0px 10px #41436A;
        width:250px;
        float: right;
        padding-left: 10px;
        z-index: 2000;
    }
    
    .nav {
        height: auto;
        color: #ffffff;
        font-size: 17px;
        font-family: 'Alike, sans-serif';
    }

    .opt{
        max-width: 20px;
        font-size: 10px;
        padding: 2px;
    }

    .dropdown {
        margin-left: -5px;
        display: flex;
        flex-direction: column;
        align-items: left;
        font-weight: bold;
        float: left;
    }
    

    .help{
        margin-left: 15px;
        font-weight: bold;
    }

    .upgrade img{
        display: none;
    }

    .subnav, .subnavbtn {
        font-size: 14px;
        float: left;
    }

    #content1{
        left: auto;
        width: 150px;
        text-align: left;
        color: #ffffff;
        flex-direction: column;
        height: 300px;
        margin-top: 50px;
        margin-left: 10px;
    }

    #content2{
        left: auto;
        width: 150px;
        text-align: left;
        color: #ffffff;
        flex-direction: column;
        height: 150px;
        margin-top: 50px;
        transform: translateX(-25%);
    }

    #content3{
        left: auto;
        width: 150px;
        text-align: left;
        color: #ffffff;
        flex-direction: column;
        height: 200px;
        margin-top: 50px;
        transform: translateX(-350%);
    }

    .subnav-content a {
        padding-top: 20px;
    }
    
    .openbtn {
        font-size: 20px;
        cursor: pointer;
        color: #F54768;
        border: none;
        background-color: transparent;
        display:block;
        float: right;
        margin: 10px;
    }
    
    .openbtn:hover {
        color: #41436A; 
    }
       
    .navbar img {
        width: 150px;
        z-index: 0;
    }

    .upgrade {
        display: flex;
        flex-direction: row;
        align-items: left;
        background: none;
        color: #41436A;
        font-size: 17px;
        float:left;
        margin-top: 15px;
        margin-left: 10px;
        margin-bottom: 15px;
        font-weight: bold;
    }

    .mobile {
        display: none;
    }

    .language{
        background:none;
        width: 100px;
        margin: 20px;
        font-weight: bold;
    }

}