body{
    background-color: #F4F4F4;
}

.navbarhome img{
    width: 200px;
    margin: 30px;
}

.title{
    color: #41436A;
    text-align: center;
    font-family: 'aclonica';
    font-size: 50px;
    margin-top: 125px;
}

.titleimage{
    width:550px;
    float:right;
}

.getbtn{
    margin-top: 50px;
    float: left;
    margin-left: 130px;
    width: 175px;
    height: 40px;
    font-size: 18px;
    border-radius: 10px;
    color: #41436A;
    border: 2px solid #41436A;
    text-align: center;
    padding-top: 5px;
    text-decoration: none;
}

.hrtag{
    width:93%;
    margin:50px;
    color:#9C9A9A;
}

.service {
    color:#DA3151;
    font-size: 28px;
    text-align: center;
    margin-bottom: 50px;
}

.service1, .service2, .service3, .service4, .service5{
    border-radius: 30px;
    width:310px;
    height:370px;
    background-color: white;
}

.service1 img{
    width: 310px;
    border-radius: 30px;
    height:320px;
}

.service2 img{
    width: 280px;
    border-radius: 30px;
    height:350px;
}

.service3 img{
    width: 300px;
    border-radius: 30px;
    height:330px;
}

.servicename1{
    color:#41436A80;
    font-size: 28px;
    float:right;
    margin-right: 115px;
    margin-top: 5px;
}

.servicename2{
    color:#41436A80;
    font-size: 28px;
    float:right;
    margin-right: 115px;
    margin-top: -25px;
}

.servicename3{
    color:#41436A80;
    font-size: 28px;
    float:right;
    margin-right: 90px;
    margin-top:-5px;
}

.service4 img{
    width: 250px;
    border-radius: 30px;
    height:300px;
    margin-left: 30px;
    margin-top: 20px;
}

.service5 img{
    width: 250px;
    border-radius: 30px;
    height:300px;
    margin-left: 30px;
    margin-top: 20px;
}

.servicename4{
    color:#41436A80;
    font-size: 28px;
    float:right;
    margin-right: 100px;
    margin-top: 5px;
}

.servicename5{
    color:#41436A80;
    font-size: 28px;
    float:right;
    margin-right: 90px;
    margin-top: 5px;
}

.left{
    width:50px;
    border:none;
    border-radius: 50px;
    float: left;
    margin-top: 10px;
    margin-left:10px;
    background-color:#F4F4F4 ;
}

.right{
    width:50px;
    border:none;
    border-radius: 50px;
    float: right;
    margin-top: 10px;
    margin-right:10px;
    background-color:#F4F4F4 ;
}

.numbercircle{
    color: #DA3151;
    background-color: #FFF7F5;
    border:1px solid #DA3151;
    text-align: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

.number{
    color: #DA3151;
    text-align: center;
    margin: 5px;
    font-size: 20px;
}

.vl{
    height: 200px;
    border-left: 2px solid #852849;
    margin-left: 20px;
}

.workright1{
    color: #FF9677;
    margin-top: 5px;
    margin-left: -10px;
}

.imgright1{
    width:180px;
    margin-left: -20px;
    float: left;
    margin-top: 5px;
}

.workright2{
    color: #FF9677;
    margin-top: 460px;
    margin-left: -10px;
}

.imgright2{
    width:200px;
    margin-left: -20px;
    float: left;
    border-radius: 10%;
    margin-top: 5px;
}

.workright3{
    color: #FF9677;
    margin-top: 450px;
    margin-left: -10px;
}

.workleft1{
    color: #FF9677;
    float: right;
    margin-right: 50px;
    margin-top: 250px;
}

.workleft2{
    color: #FF9677;
    margin-top: 730px;
    float: right;
    margin-right: -230px;
}

.workleft3{
    color: hsl(14, 100%, 73%);
    margin-top: 240px;
    float: right;
    margin-right: 50px;
}

.imgleft1{
    width:200px;
    margin-top: 150px;
    border-radius: 10%;
    float: right;
    margin-right: -75px;
}

.imgleft2{
    width:270px;
    margin-top: 610px;
    float: right;
    margin-right: -190px;
}

.imgleft3{
    width:200px;
    margin-top: 130px;
    float: right;
    margin-right: -110px;
    border-radius: 10%;
}

.all{
    text-decoration: none;
    float: right;
    color: #974063;
    font-size: 16px;
    margin-right: 50px;
    margin-bottom: 20px;
    font-weight: 500;
}

.story{
    border-radius: 20px;
    background-color: white;
    height: 530px;
    width: 400px;
}

.storyname{
    color: #974063;
    text-align: center;
    padding-top: 20px;
    font-size: 20px;
}

.storyimg{
    margin-left:65px;
    width:300px;
}

.para{
    font-style: italic;
    text-align: center;
    font-size: 14px;
    color: #858485;
    margin-left: 30px;
    margin-right: 30px;
}

.see{
    text-decoration: none;
    float: right;
    color: #974063;
    font-size: 14px;
    margin-right: 40px;
    font-weight: 500;
}

.registerbtn{
    text-align: center;
}

.topic{
    color: #41436A;
    font-size: 24px;
}

.sub{
    color: #974063;
}

.regbtn{
    background-color: #41436A;
    color: white;
    border: none;
    width: 150px;
    height: 40px;
    font-size: 16px;
    border-radius: 10px;
}

.navigatebullet1{
    width: 10px;
    height: 10px;
    background-color: #DA3151;
    border-radius: 50px;
    margin-left: 620px;
    margin-top: 30px;
}

.navigatebullet2{
    width: 10px;
    height: 10px;
    background-color: #dabb31;
    border: 50px;
    border-radius: 50px;
    margin-top: 30px;
    margin-left: -560px;
}

.navigatebullet4{
    width: 10px;
    height: 10px;
    background-color: #dabb31;
    border-radius: 50px;
    margin-left: 620px;
    margin-top: 30px;
}

.navigatebullet5{
    width: 10px;
    height: 10px;
    background-color: #DA3151;
    border: 50px;
    border-radius: 50px;
    margin-top: 30px;
    display: block;
    margin-left: -560px;
}

@media only screen and (max-width: 768px) {
    .title {
        font-size: 130%;
        margin: 0 auto;
        margin-top: 20px;
    }

    .navbarhome img {
        width: 120px; /* Adjust size for smaller screens */
        margin: 20px;
    }
    
    .titleimage {
        width: 100%; /* Make image responsive */
        max-width: 500px; /* Limit maximum width */
        display: block; /* Ensure proper alignment */
        margin: 0 auto; /* Center the image */
    }

    .getbtn {
        margin-top: 50px; /* Adjust spacing for smaller screens */
        width: auto; /* Adjust button width for smaller screens */
        height: auto;
        font-size: 70%; /* Adjust font size for smaller screens */
        border-radius: 8px;
        padding: 2px;
        padding-left: 5px;
        padding-right: 5px;
        color: #41436A;
        border: 1px solid #41436A;
        text-align: center;
        text-decoration: none;
        display: block; /* Ensure proper alignment */
        margin: 0 auto; /* Center the button */
    }

    .service{
        font-size: 100%;
    }

    .left, .right{
        max-width:30px;
    }

    .workright{
        margin: auto;
        margin-left: 15px;
    }

    .workright2{
        margin-top: 450px;
        margin-left: 15px;
    }

    .workright3{
        margin-top: 430px;
        margin-left: 15px;
    }

    .imgright1, .imgright2{
        width:100px;
        float: left;
        margin-left: auto;
        margin-top: 5px;
    }

    .workleft1{
        margin-right: 20px;
        margin-top: 245px;
    }

    .workleft2{
        margin-right: 20px;
        margin-top: 330px;
    }

    .workleft3{
        margin-right: 20px;
        margin-top: 300px;
    }

    .imgleft1{
        margin-right: auto;
        margin-top: 5px;
        width:100px;
    }

    .imgleft2{
        margin-right: auto;
        margin-top: 5px;
        width:150px;
    }

    .imgleft3{
        margin-right: auto;
        margin-top: 5px;
        width:150px;
    }

    .service1, .service2, .service3, .service4, .service5{
        border-radius: 30px;
        width:310px;
        height:370px;
        background-color: white;
    }
}

@media only screen and (max-width: 493px) {
    .title {
        font-size: 100%;
        margin: 0 auto;
        margin-top: 20px;
    }

    .navbarhome img {
        width: 120px; 
        margin: 20px;
    }
    
    .titleimage {
        width: 100%; 
        max-width: 550px;
        display: block;
        margin: 0 auto; 
    }

    .getbtn {
        margin-top: 10px;
        float: left;
        margin-left: 130px;
        max-width: 150px;
        max-height: 30px;
        font-size: 70%;
        border-radius: 10px;
        color: #41436A;
        border: 2px solid #41436A;
        text-align: center;
        padding-top: 5px;
        text-decoration: none;
        margin-left: 20px;
    }

    .service{
        font-size: 80%;
    }

    .left, .right{
        width:40px;
    }

    .numbercircle{
        max-width: 30px;
        max-height: 30px;
    }

    .number{
        margin: 0 auto;
        font-size: 100%;
        padding: 3px;
    }
    
    .vl{
        height: 200px;
        border-left: 1px solid #852849;
        margin-left: 15px ;
    }

    .workright1{
        margin-top: 5px;
        margin-left: 15px;
        font-size: 80%;
    }

    .workright2{
        margin-top: 445px;
        margin-left: 15px;
        font-size: 80%;
    }

    .workright3{
        margin-top: 445px;
        margin-left: 15px;
        font-size: 80%;
    }

    .imgright1, .imgright2{
        width:100px;
        float: left;
        margin-left: auto;
        margin-top: 5px;
    }

    .workleft1{
        margin-right: 20px;
        margin-top: 235px;
        font-size: 80%;
    }

    .workleft2{
        margin-right: 40px;
        margin-top: 330px;
        font-size: 80%;
    }

    .workleft3{
        margin-right: 20px;
        margin-top: 300px;
        font-size: 80%;
    }

    .imgleft1{
        margin-right: auto;
        margin-top: 5px;
        width:100px;
    }

    .imgleft2{
        margin-right: auto;
        margin-top: 5px;
        width:150px;
    }

    .imgleft3{
        margin-right: auto;
        margin-top: 5px;
        width:150px;
    }

    .service1, .service2, .service3, .service4, .service5{
        max-width:110px;
        max-height:150px;
        border-radius: 10px;
    }

    .service2 img, .service3 img, .service4 img, .service5 img{
        max-width: 100px;
        max-height: 130px;
        margin: 0 auto;
        border-radius: 10px;
    }

    .service1 img{
        max-height: 120px;
        max-width: 110px;
    }

    .servicename1{
        font-size: 80%;
        margin-top: 10px;
        margin-right: 40px;
    }

    .servicename2, .servicename3, .servicename4, .servicename5{
        font-size: 80%;
        margin: 0 auto;
        margin-right: 30px;
    }

    .all{
        font-size: 70%;
    }

    .story{
        max-height: 400px;
        max-width: 300px;
    }

    .hrtag{
        width: 300px;
    }

    .storyimg{
        max-width: 200px;
    }

    .storyname, .para, .see{
        font-size: 70%;
    }

    #story2{
        display:none;
    }

    .topic, .sub{
        font-size: 80%;
    }

    .regbtn{
        font-size: 60%;
        max-width:120px;
        max-height: 35px;
    }

    .footer{
        display: block;
    }
}