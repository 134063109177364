.signupimg{
    background-color: #F54768;
    height: 720px;
    width:800px;
    margin-top:10px;
    margin-left: 20px;
    border-radius: 20px;
}

.signupimg img{
    width: 500px;
    margin-left: 100px;
}

.signup{
    background-color: #F54768;
    height: 720px;
    width:530px;
    margin-top:10px;
    margin-right: 20px;
    border-radius: 20px; 
    padding-top: 50px;
}

.loginimg{
    background-color: #F54768;
    height: 720px;
    width:750px;
    margin-top:10px;
    margin-right: 20px;
    border-radius: 20px;
}

.loginimg img{
    width: 500px;
    margin-left: 150px;
}

.login{
    background-color: #F54768;
    height: 720px;
    width:600px;
    margin-top:10px;
    border-radius: 20px; 
    padding-top: 50px;
    margin-left: 20px;
}

.signup .form{
    background-color: #ffffff;
    height: auto;
    width:450px;
    margin-top:100px;
    margin-left: 10px;
    border-radius: 20px; 
    padding-top: 50px;
    padding-left: 50px;
}

.signup .form input{
    width: 350px;
    border-radius: 50px;
}

.login .form{
    background-color: #ffffff;
    height: auto;
    width:450px;
    margin-top:100px;
    margin-left: 70px;
    border-radius: 20px; 
    padding-top: 50px;
    padding-left: 50px;
}

.login .form input{
    width: 350px;
    border-radius: 50px;
}

.form h4{
    color: #F54768;
}

.regbtn{
    background-color: #9a9dce;
    color: white;
    border: none;
    width: 150px;
    height: 40px;
    font-size: 16px;
    border-radius: 10px;
}

.logbtn, .signupbtn{
    background-color: #41436A;
    color: white;
    border: none;
    width: 150px;
    height: 40px;
    font-size: 16px;
    border-radius: 10px;
    margin-right: 110px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.link {
    float: left;
}

.btn1{
    margin-top: 50px;
    margin-right: 110px;
    width: 150px;
    height: 40px;
    font-size: 18px;
    border-radius: 10px;
    color: #41436A;
    border: 2px solid #41436A;
    text-align: center;
    padding-top: 5px;
    text-decoration: none;
    float: right;
}

.btn2{
    margin-top: 50px;
    margin-left: 130px;
    width: 150px;
    height: 40px;
    font-size: 18px;
    border-radius: 10px;
    color: #41436A;
    border: 2px solid #41436A;
    text-align: center;
    padding-top: 5px;
    text-decoration: none;
    float: left;
}

.social{
    font-size: 28px;
    color: #41436A;
    font-weight: bold;
    letter-spacing: 70px;
    text-align: center;
    margin-top: 20px;
}

.forget .form{
    background-color: #ffffff;
    height: auto;
    width:450px;
    margin-left: 70px;
    margin-top:10px;
    border-radius: 20px; 
    padding-top: 50px;
    padding-left: 50px;
}

.forget{
    background-color: #F54768;
    height:720px;
    width:600px;
    margin-top:10px;
    border-radius: 20px; 
    padding-top: 50px;
    margin-left: 20px;
}

.forget .form input{
    width: 350px;
    border-radius: 50px;
}

.forgetimg{
    background-color: #F54768;
    height: 720px;
    width:725px;
    margin-top:10px;
    margin-left: 20px;
    border-radius: 20px;
}

.forgetimg img{
    width: 500px;
    margin-left: 100px;
}

.backtoLogin{
    color: #41436A;
    font-size: 14px;
}

.backtoLogin a{
    text-decoration: none;
    color: #41436A;
}

@media only screen and (max-width: 493px) {
    .login, .forget{
        max-width: 405px;
        left: 4%;
        transform: translateX(-4%);
        z-index:1;
    }

    .signup{
        background-color: inherit;
        margin-top: 300px;
        margin-left: -235px;
    }

    .login .form, .forget .form{
        width: 100%;
        max-width: 380px;
        direction: ltr;
        left: 15%;
        transform: translateX(-15%);
        top:-10%;
    }

    .signup .form{
        width: 100%;
        max-width: 380px;
        margin-top: 40px;
    }

    .loginimg{
        width: 200%;
        max-width: 405px;
        max-height: 208px;
        height: 200%;
        position: relative;
        left: -70%;
        transform: translateX(-90%);
        transform: translateY(330%);
        z-index:0;
        background-color: inherit;
    }

    .forgetimg {
        width: 200%;
        max-width: 405px;
        max-height: 208px;
        height: 200%;
        position: relative;
        left: -78%;
        transform: translateX(-90%);
        transform: translateY(330%);
        z-index:0;
        background-color: inherit;
    }

    .signupimg{
        width: 200%;
        max-width: 400px;
        max-height:905px;
        height: 200%;
        margin-left: 5px;
        margin-top:4px;
    }

    .loginimg img{
        width: 100%;
        max-width: 350px;
        margin-top:-120px;
        margin-left: 250px;
    }

    .forgetimg img{
        width: 100%;
        max-width: 350px;
        margin-top:-130px;
        float:left;
    }

    .signupimg img{
        margin-left: 100px;
        width: 100%;
        max-width: 400px;
        margin-top:50px;
    }

    .signup .mb-3 input{
        width:270px;
    }

    .login .mb-3 input, .forget .mb-3 input{
        width: 100%;
        max-width: 270px;
    }        

    .backLink{
        left: -12%; 
        transform: translateX(-25%);
        position: relative;
    }

    .btn1{
        float: right;
        width: 120px;
        font-size: 80%;
        position: absolute;
        margin-top: 100px;
        left: 25%; 
        transform: translateX(25%);
    }

    .btn2{
        float: left;
        width: 120px;
        font-size: 80%;
        margin-top: 50px;
        position: absolute;
    }

    .signupbtn {
        direction: ltr;
        left: 25%;
        transform: translateX(-25%);
    }

    .logbtn {
        direction: ltr;
        left: 25%;
        transform: translateX(-25%);
    }
    
}