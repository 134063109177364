.loginimg{
    background-color: #F54768;
    height: 720px;
    width:750px;
    margin-top:10px;
    margin-bottom:10px;
    margin-right: 20px;
    border-radius: 20px;
}

.loginimg img{
    width: 500px;
    margin-left: 150px;
}

.login{
    background-color: #F54768;
    height: 720px;
    width:600px;
    border-radius: 20px; 
    padding-top: 50px;
    margin-top:10px;
    margin-bottom:10px;
    margin-left: 20px;
}

.login .form{
    background-color: #ffffff;
    height: auto;
    width:450px;
    margin-top:50px;
    margin-left: 70px;
    border-radius: 20px; 
    padding-top: 50px;
    padding-left: 50px;
}

.login .form input{
    width: 350px;
    border-radius: 50px;
}

.form h4{
    color: #F54768;
}

.regbtn{
    background-color: #41436A;
    color: white;
    border: none;
    width: 150px;
    height: 40px;
    font-size: 16px;
    border-radius: 10px;
}

.logbtn, .signupbtn{
    background-color: #41436A;
    color: white;
    border: none;
    width: 150px;
    height: 40px;
    font-size: 16px;
    border-radius: 10px;
    margin-left: 100px;
    margin-top: 25px;
}

.link {
    float: left;
}

.btn1{
    margin-top: 50px;
    margin-right: 110px;
    width: 150px;
    height: 40px;
    font-size: 18px;
    border-radius: 10px;
    color: #41436A;
    border: 2px solid #41436A;
    text-align: center;
    padding-top: 5px;
    text-decoration: none;
    float: right;
}

.btn2{
    margin-top: 50px;
    margin-left: 130px;
    width: 150px;
    height: 40px;
    font-size: 18px;
    border-radius: 10px;
    color: #41436A;
    border: 2px solid #41436A;
    text-align: center;
    padding-top: 5px;
    text-decoration: none;
    float: left;
}

.social{
    font-size: 28px;
    color: #41436A;
    font-weight: bold;
    letter-spacing: 70px;
    text-align: center;
    margin-top: 20px;
}

.forget .form{
    background-color: #ffffff;
    height: auto;
    width:450px;
    margin-top:10px;
    margin-left: 70px;
    border-radius: 20px; 
    padding-top: 50px;
    padding-left: 50px;
}

.forget{
    background-color: #F54768;
    height:720px;
    width:600px;
    margin-top:10px;
    border-radius: 20px; 
    padding-top: 50px;
    margin-left: 20px;
}

.forget .form input{
    width: 350px;
    border-radius: 50px;
}

.forgetimg{
    background-color: #F54768;
    height: 720px;
    width:725px;
    margin-top:10px;
    margin-left: 20px;
    border-radius: 20px;
}

.forgetimg img{
    width: 500px;
    margin-left: 100px;
}

.backtoLogin{
    color: #41436A;
    font-size: 14px;
}

.backtoLogin a{
    text-decoration: none;
    color: #41436A;
}