.servicebtn{
    background-color: #41436A;
    color: white;
    margin-right: 20px;
    width: 150px;
    height: 40px;
    border-radius: 30px;
    border: none;
    margin-left: 100px;
    margin-top: 20px;
}

.subnavbar2{
    display: none;
}

.notification{
    border: none;
    background-color:#F4F4F4;
    margin-left: 100px;
    font-size: 24px;
    color: rgb(228, 65, 65);
}

.profile, .horoscope, .subscription{
    width:500px;
    border-radius: 20px;
    background-color: white;
    margin: 20px;
    height: auto;
    padding: 20px;
    margin-left: 75px;
    margin-bottom: 30px;
}

.horoscope h5{
    text-align: center;
    color: #41436A;
    font-weight: 600;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 10px;
}

.horoscopebtn{
    border: 2px solid #41436A;
    border-radius: 20px;
    color: #41436A;
    margin-top: 30px;
    height: 40px;
    background-color: #e9eaf7;
    margin-bottom: 20px;
}

.mobileReq{
    display: none;
}

.groom{
    width: 125px;
    margin-bottom: 30px;
    margin-top: 10px;
}

.profile a{
    text-decoration: none;
    font-size: 14px;
    color: #41436A;
    float: right;
    margin-right: 30px;
}

.profile .name{
    margin-left: 30px;
    color: #41436A;
    font-weight: bold;
    font-size: 22px;
    margin-top: 20px;
}

.profile .location{
    margin-left: 30px;
    color: #7e7f8d;
    font-weight: bold;
    font-size: 16px;
}

.label{
    color: #41436A;
    margin-top: 10px;
    margin-left: 30px;
}

.value{
    float: right;
    margin-right: 30px;
    margin-top: 10px;
    color: #41436A;
}

.editprofilebtn{
    background-color: #41436A;
    color: white;
    margin-right: 20px;
    width: 150px;
    height: 40px;
    border-radius: 30px;
    border: none;
    margin-left: 30px;
    margin-top: 40px;
    margin-bottom: 10px;
}

.completebtn{
    background-color: #e9eaf7;
    color: #41436A;
    width: 150px;
    height: 40px;
    border-radius: 30px;
    border: 2px solid #41436A;
    margin-right: 30px;
    margin-top: 40px;
    float: right;
    margin-bottom: 10px;
}

.paybtn{
    background-color: #e9eaf7;
    color: #41436A;
    width: 150px;
    height: 40px;
    border-radius: 30px;
    border: 2px solid #41436A;
    margin-top: 40px;
    margin-bottom: 10px;
}

.subsbtn{
    width: 100px;
    border: none;
    background-color: #eceaea;
    height: 40px;
    border-radius: 50px;
    color: #828285;
    font-weight: bold;
    margin: 20px;
    box-shadow: 2px 5px 5px 5px #dddcdc;
}

.amount{
    float: right;
    margin: 20px;
    color: #41436A;
    font-weight: bold;
}

.subscribebtn{
    border: 2px solid #41436A;
    border-radius: 20px;
    color: #41436A;
    margin-top: 40px;
    height: 40px;
    background-color: #e9eaf7;
    margin-bottom: 20px;
}

.subdetails{
    margin-left: 20px;
    color: #41436A;
    margin-top: 20px;
}

.subvalue{
    color: #41436A;
    float: right;
    margin-right: 20px;
    margin-top: 20px;
}

.rewards, .requests{
    border-radius: 20px;
    background-color: white;
    height: auto;
    padding: 20px;
    margin-left: 100px;
    margin-bottom: 30px;
    margin-right: 100px;
}

.vl1{
    height: 200px;
    border-left: 1px solid #c8c9d4;
    margin-top: 10px;
}

.vl2{
    height: 350px;
    border-left: 1px solid #c8c9d4;
    margin-top: 10px;
    margin-left: 90px;
}

.rewardstitle1{
    color: #41436A;
    font-weight: bold;
    margin-top: 20px;
    margin-left: 50px;
}

.rewardstitle2{
    color: #41436A;
    font-weight: bold;
    margin-top: 20px;
}

.reqtitle{
    color: #41436A;
    font-weight: bold;
    margin-top: 20px;
    text-align: center;
    margin-left: -50px;
}

.requestname{
    margin-left: 100px;
    margin-top: 50px;
    margin-bottom: 20px;
    font-weight: bold;
}

.requestprofile{
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    height: auto;
    padding: 20px;
    margin-left: 20px;
    margin-bottom: 30px;
    margin-right: 10px;
    margin-top: 30px;
    box-shadow: 5px 5px 5px 5px #dddcdc;
}

.requestimg{
    width: 75px;
}

.requsername{
    font-size: 18px;
    color: #41436A;
    font-weight: bold;
    margin-top: -10px;
    margin-left: 20px;
}

.viewbtn{
    background-color: #41436A;
    color: white;
    margin-right: 20px;
    width: 120px;
    height: 40px;
    border-radius: 30px;
    border: none;
    margin-left: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.reqlocation{
    color: #7e7f8d;
    font-weight: bold;
    font-size: 14px;
    margin-left: 20px;
}

.visa{
    width: 75px;
    margin-top: 20px;
}

.accno{
    color: #41436A;
    margin-left: 100px;
    margin-top: 25px;
}

.managecard a {
    text-decoration: none;
    color: #41436A;
    font-size: 14px;
    font-weight: 600;
}

.managePost a{
    text-decoration: none;
    color: #41436A;
    font-size: 14px;
    font-weight: 600;
}

.managecard{
    float: right;
    margin-right: 20px;
    margin-top: 20px;
}

.rewarddetails{
    margin-left: 50px;
    color: #41436A;
    margin-top: 20px;
}

.rewardvalue{
    margin-right: 50px;
    color: #41436A;
    margin-top: 20px;
    float: right;
}

.managerewards{
    text-align: center;
    margin-right: 20px;
    margin-top: 30px;
}

.managerewards a {
    text-decoration: none;
    color: #41436A;
    font-size: 16px;
    font-weight: 600;
}

.account-deactive{
    width:1100px;
    border-radius: 10px;
    margin: 50px;
    background-color: #f44336; 
    color: white;
    height: 100px;
    padding: 30px;
    text-align: center;
    margin-left: 100px;
}

.account-active{
    width:1100px;
    border-radius: 10px;
    margin: 50px;
    background-color: #0caf55; 
    color: white;
    height: 100px;
    padding: 30px;
    text-align: center;
    margin-left: 100px;
}

.account-cancel{
    width:1100px;
    border-radius: 10px;
    margin: 50px;
    background-color: #6d6c6c; 
    color: white;
    height: 100px;
    padding: 30px;
    text-align: center;
    margin-left: 100px;
}

@media only screen and (max-width: 493px) {
    .subnavbar{
        display:none;
    }

    .profile{
        margin-left: 10px;
        max-width: 380px;
        font-size:smaller;
        margin-top: 75px;
    }

    .accStatus{
        align-items: center;
        left: 20%;
        transform: translateX(-35%);
    }

    .editprofilebtn, .completebtn{
        max-width: 120px;
    }

    .horoscope{
        transform: translateX(-66%);
        max-width: 380px;
        font-size:smaller;
        margin-top: 750px;
    }

    .subscription{
        transform: translateX(-66%);
        max-width: 380px;
        font-size:smaller;
    }

    .rewards{
        transform: translateX(-21%);
        max-width: 380px;
        font-size:smaller;
        width:100%;
        margin-top: -20px;
    } 

    .rewardstitle1, 
    .rewarddetails{
        margin-left: 0;
        direction: ltr;
    }

    .rewardstitle2{
        font-size:medium;
    }

    .rewardvalue{
        margin-top: 20px;
        margin-left: 0;
    }

    .visa{
        width:50px;
    }

    .accno{
        font-size: smaller;
        margin-left: -30px;
        margin-top: 50px
    }

    .managecard{
        margin-left: -30px;
    }

    #postStatus{
        margin-left: -250px;
    }

    .paybtn{
        width:100px;
        margin-left: -10px;
    }

    .requestsMobile{
        transform: translateX(8%);
        max-width: 380px;
        font-size:smaller;
        width:100%;
    }

    .requests{
        display: none;
    }

    .mobileReq{
        display: block;
    }

    .requestname{
        margin-left: 30px;
    }

    .vl2{
        margin-left: 25px;
    }

    .requestprofile{
        width:380px;
        margin-left: -10px;
        height:150px;
    }

    #sentprofile{
        height:175px;
    }

    .requestimg{
        margin-left: 10px;
        width:50px;
    }

    .requsername{
        margin-top: 10px;
        position: absolute;
        margin-left: 20px;
    }

    .reqlocation{
        margin-top: -60px;
        position: absolute;
        margin-left: 130px;
    }

    .statusconfirm{
        margin-top: -60px;
        transform: translateX(225%);
        position: absolute;
    }

    .viewbtn{
        transform: translateY(-150%);
        margin-left: 120px;
    }

    .subnavbar2{
        margin-left: 12px;
        margin-top:-10px;
        position: absolute;
        display: flex;
        z-index: 1000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        background-color: white;
        width:390px;
        border-radius: 10px;
        padding-left: 10px;
        padding-bottom: 10px;
        padding-right: 10px;
        padding-top:-20px;
    }

    .servicebtn{
        background-color: inherit;
        width: 60px;
        height: 60px;
        border-radius: 30px;
        border: 2px solid #dedff1;
        margin-left: 0;
        margin-top: 20px;
    }

    .subnavimg{
        width:40px;
        align-items: center;
    }

    .account-active, .account-deactive, .account-cancel{
        width:400px;
        border-radius: 10px;
        margin-top: 30px;
        color: white;
        height: 100px;
        padding: 15px;
        text-align: center;
        margin-left: 5px;
    }

    .tabs{
        margin-left: 20px;
    }

    .tabs button{
        border: none;
    }

}