.footer{
    background-color: #202238;
    color: white;
}

.footerTitle{
    margin-top: 50px;
    font-weight: bold;
    font-size: 18px;
    margin-left: 50px;
}

.footerTitle1, .footerTitle2, .footerTitle4, .footerTitle3{
    margin-top: 50px;
    font-weight: bold;
    font-size: 18px;
    margin-left: 50px;
}

.footerTitleSocial{
    margin-top: 50px;
    font-weight: bold;
    font-size: 18px;
    margin-left: 50px;
}

.copyright{
    background-color: #0a0d20;
}

.copy{
    font-size: 14px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.quickLinks1 ul, .quickLinks2 ul, .quickLinks3 ul{
    list-style: none;
    margin-top: 10px;
    margin-left: 20px;
    font-size: 14px;
    margin-bottom: 20px;
}

.quickLinks1 li, .quickLinks2 li, .quickLinks3 li{
    margin-bottom: 5px;
}

.quickLinksSocial ul{
    list-style: none;
    margin-top: 10px;
    margin-left: 20px;
    font-size: 14px;
    margin-bottom: 20px;
}

.quickLinksSocial li{
    margin-bottom: 5px;
}

.footer a{
    text-decoration: none;
    color: white;
}

.footerLogo{
    width:250px;
    margin-top: 50px;
}

.socialmedia{
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 50px;
    margin-top: 10px;
    margin-left: 50px;
    margin-bottom: 25px;
}

.socialmedia .fa-instagram{
    color: rgb(255, 255, 255);
    background-color: rgb(190, 9, 39);
    padding-left: 6px;
}

.socialmedia .fa-facebook{
    color: rgb(255, 255, 255);
    background-color: #3B5998;
    padding-left: 9px;
}

.socialmedia .fa-google{
    color: rgb(255, 255, 255);
    background-color: rgb(209, 6, 6);
    padding-left: 6px;
}

.socialmedia .fa {
    width: 30px;
    height: 30px;
    padding-top: 5px;
    border-radius: 50px;
    text-align: center;
    margin-right: 10px;
}
  
.fa:hover {
    opacity: 0.7;
}

.mission{
    margin-top: 20px;
    margin-right: 50px;
}

.fa-phone{
    margin-right: 20px;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.fa-envelope{
    margin-right: 20px;
    font-size: 18px;
    margin-top: 5px;
    margin-bottom: 10px;
}

.fa-map-marker{
    margin-right: 20px;
    font-size: 20px;
    margin-top: 5px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 493px) {
    .mission {
        margin-top: 10px; 
        margin-left: 10px; 
        width: 310px;
        font-size: 100%;
        display: flex;
    }

    .footerTitle1 {
        margin-top: 220px;
        overflow: hidden;
        margin-left: -100px;
        display: flex;
    }

    .footerTitle2 {
        margin-top: 220px;
        overflow: hidden;
        display: flex;
        width: 150px;
        margin-left: 10px;
    }

    .footerTitle4{
        margin-top:480px;
        overflow: hidden;
        margin-left: -275px;
        display: flex;
    }

    .footerTitle3 {
        margin-top:410px;
        overflow: hidden;
        margin-left: -275px;
        display: flex;
    }

    .footerTitleSocial{
        display: flex;
        margin-left: 6px;
        text-align: center;
    }

    .quickLinksSocial{
        margin-left:-45px;
        display: flex;
        width: 250px;
    }

    .quickLinks1{
        margin-left:-150px;
    }

    .quickLinks2{
        margin-left:-40px;
        width: 150px;
    }

    .quickLinks3{
        margin-left:-300px;
    }
}