.navbar img{
    width:150px;
    margin-left: 30px;
    margin-top:10px
}

.nav{
    background-color: white;
    margin: 10px;
    border-radius: 20px;
    height: 85px;
}

.adminnavlink a{
    text-decoration: none;
    color: #41436A;
}

.adminnavlink{
    margin-left: 100px;
    margin-top: 5px;
}

.language, .help, .userlogin{
    border: none;
    color: #41436A;
    margin-top: 5px;
}

.language{
    margin-left: 10px;
    margin-right: 15px;
}

.help a {
    text-decoration: none;
}


.openbtn {
    font-size: 20px;
    cursor: pointer;
    color: #F54768;
    padding: 10px 15px;
    border: none;
    background-color: #ffffff;
}
  
.openbtn:hover {
    color: #41436A;
}

.nav1, .nav2, .nav3, .nav4{
    margin-left:30px;
}

.fa-user-circle{
    color:#41436A;
    font-size: 24px;
}

.dropbtn {
    color: #41436A;
    padding: 16px;
    font-size: 16px;
    border: none;
    background-color: #ffffff;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #eb2323;
    min-width: 80px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 30px;
    text-align: center;
    margin-top: -10px;
}

.dropdown-content a {
    color: #ffffff;
    padding: 10px 12px;
    text-decoration: none;
    display: block;
    font-size: 14px;
    font-weight: bold;
}

.dropdown-content a:hover {
    background-color: #f85454;
    border-radius: 30px;
    color:black;
    font-weight: bold;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropbtn i {
    padding: 5px;
}

.opt{
    border-color: #41436A;
    background-color: #ffffff;
}

.adminsubnav {
    float: left;
    overflow: hidden;
}
  
.adminsubnav .adminsubnavbtn {
    font-size: 14px;
    border: none;
    outline: none;
    color: #41436A;
    padding: 10px 16px;
    background-color: inherit;
    font-family: inherit;
    font-weight: bold;
    margin-top: 17px;
}

.navbar a:hover, .adminsubnav:hover .adminsubnavbtn {
    border-color: #F54768;
    color: #F54768;
}

.adminNav{
    margin-left: -75px;
}
